import React from "react";

import { iCategorie, iService, iSubCategory } from "../../Core/interface";

interface IProps {
  category: iCategorie;
}

const ServicesList: React.FC<IProps> = ({ category }) => {
  return (
    <ul className="list-sous-categories">
      {category?.attributes.subCategories?.data?.map(
        (subCategory: iSubCategory) => (
          <li className="sous-categorie" key={subCategory.attributes.name}>
            <h3 className="title-sous-categorie">
              {subCategory.attributes.name}
            </h3>
            <ul className="services">
              {subCategory.attributes.service?.map(
                (service: iService, index: number) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <li className="service" key={index}>
                    {service.name && (
                      <span className="name">{service.name}</span>
                    )}
                    {service.price && (
                      <span className="price">
                        {service.price}
                        {service.plus && <i className="plus">+</i>}
                      </span>
                    )}
                    {service.description && (
                      <span className="description">{service.description}</span>
                    )}
                  </li>
                )
              )}
            </ul>
          </li>
        )
      )}
    </ul>
  );
};

export default ServicesList;
