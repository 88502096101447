import React from "react";
import { Link, NavLink } from "react-router-dom";
import { iCategorie } from "../Core/interface";

import { useGql } from "../Core/gqlHooks";
import { queryCategories, queryPageHome } from "../Core/services";

import { setMeta, setPageTitle, setJsonLd } from "../Core/utils";

import "./Home.scss";

const Home = () => {
  const { data: categoryData, loading: categoryLoading } = useGql({
    query: queryCategories,
  });

  const { data: homeData } = useGql({
    query: queryPageHome,
  });

  setJsonLd({});
  setPageTitle(homeData?.pageHome.data.attributes.pageTitle);
  setMeta(
    homeData?.pageHome.data.attributes.keywords,
    homeData?.pageHome.data.attributes.metaDescription
  );

  return (
    <div className="home-container">
      {!categoryLoading && (
        <div className="main-container _full-height">
          <nav className="home-nav _full-height">
            <ul className="home-nav-list">
              {categoryData?.categories.data?.map((categorie: iCategorie) => (
                <li className="item" key={categorie.attributes.name}>
                  <Link
                    className="link"
                    to={`/services/${categorie.attributes.slug}`}
                    title={categorie.attributes.name}
                  >
                    <span className="title">{categorie.attributes.name}</span>
                    <span className="short-desc">
                      {categorie.attributes.description}
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
            <NavLink
              to="/contact"
              className="btn -pink"
              title="Info / Rendez-vous"
            >
              Info / Rendez-vous
            </NavLink>
          </nav>
        </div>
      )}
    </div>
  );
};

export default Home;
