import React from "react";
import { NavLink, useLocation } from "react-router-dom";

import { ReactComponent as IconFacebook } from "../../Assets/images/icn-facebook.svg";
import { ReactComponent as IconInstagram } from "../../Assets/images/icn-instagram.svg";

import "./Footer.scss";

const Footer = () => {
  const location = useLocation();
  const showContactButton =
    location.pathname !== "/" && location.pathname !== "/contact";

  if (!showContactButton) {
    return null;
  }

  return (
    <footer className="main-footer">
      <div className="main-container">
        <div className="main-footer-container">
          <ul className="list-social-media">
            <li className="item">
              <a
                className="link"
                href="https://www.facebook.com/coifferieem"
                rel="noreferrer"
                target="_blank"
              >
                <IconFacebook className="icon-social" />
              </a>
            </li>
            <li className="item">
              <a
                className="link"
                href="https://www.instagram.com/coifferieem"
                rel="noreferrer"
                target="_blank"
              >
                <IconInstagram className="icon-social" />
              </a>
            </li>
          </ul>

          <NavLink
            to="/contact"
            className="btn -pink"
            title="Info / Rendez-vous"
          >
            Info / Rendez-vous
          </NavLink>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
