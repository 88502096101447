import React from "react";
import { NavLink } from "react-router-dom";
import { setJsonLd } from "../Core/utils";
import PageSubHeader from "../Components/pageSubHeader/PageSubHeader";

import PageHeader from "../Components/pageHeader/PageHeader";

const NotFound = () => {
  setJsonLd({});
  return (
    <div className="services-container page-container">
      <PageHeader title="Oops!" />
      <div className="main-container">
        <div className="page-content">
          <div className="spacer col-left" />
          <div className="produits col-center">
            <PageSubHeader
              title="Cette page n'existe pas!"
              intro={
                <NavLink className="link" to="/">
                  Retourner à l&apos;accueil
                </NavLink>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
