import React from "react";
import { NavLink, useLocation } from "react-router-dom";

import { useGql } from "../../Core/gqlHooks";
import { queryCategories } from "../../Core/services";

import "./Nav.scss";

interface NavListProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, any> | null;
  closeMenu?: () => void;
}

const NavList: React.FC<NavListProps> = ({ data, closeMenu }) => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <ul className="list">
      <li className="item">
        <NavLink
          title="Nos Services"
          className={`link ${
            pathname.indexOf("/services/") !== -1 ? "active" : ""
          }`}
          to={`/services/${data?.categories.data[0].attributes.slug}`}
          onClick={closeMenu}
        >
          Nos Services
        </NavLink>
      </li>
      {/* <li className="item">
        <NavLink
          title="Les Produits"
          className="link"
          to="/produits"
          onClick={closeMenu}
        >
          Les Produits
        </NavLink>
      </li> */}
      <li className="item">
        <NavLink
          title="À Propos"
          className="link"
          to="/apropos"
          onClick={closeMenu}
        >
          À Propos
        </NavLink>
      </li>
      <li className="item">
        <NavLink
          title="Nous Contacter"
          className="link"
          to="/contact"
          onClick={closeMenu}
        >
          Nous Contacter
        </NavLink>
      </li>
    </ul>
  );
};

interface iNav {
  openMenu?: () => void;
  closeMenu?: () => void;
  handleHamburgerClick?: () => void;
  menuOpened: boolean;
}

const Nav: React.FC<iNav> = ({
  openMenu,
  closeMenu,
  handleHamburgerClick,
  menuOpened,
}) => {
  const { data, loading } = useGql({
    query: queryCategories,
    vars: { order: 1 },
  });

  return (
    <>
      {/* Desktop */}
      <nav className="main-nav">
        {!loading && data?.categories?.data?.length > 0 && (
          <NavList data={data} />
        )}
      </nav>

      <button
        className={`hamburger ${menuOpened ? "opened" : ""}`}
        onClick={handleHamburgerClick}
      >
        <span className="line" />
        <span className="line" />
        <span className="line" />
      </button>

      {/* Mobile */}
      <nav className={`main-nav-mobile ${menuOpened ? "opened" : ""}`}>
        {!loading && data?.categories?.data?.length > 0 && (
          <NavList data={data} closeMenu={closeMenu} />
        )}
      </nav>
    </>
  );
};

export default Nav;
