import React, { useEffect } from "react";
import { useParams } from "react-router";
import { NavLink, useNavigate } from "react-router-dom";
import { useGql } from "../Core/gqlHooks";
import {
  queryPageService,
  queryCategoriesWithServices,
} from "../Core/services";

import ServicesList from "../Components/servicesList/ServicesList";
import PageHeader from "../Components/pageHeader/PageHeader";
import { iCategorie } from "../Core/interface";
import { setMeta, setPageTitle, setJsonLd } from "../Core/utils";

import "./Services.scss";

const Services = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const { data: pageServiceData } = useGql({
    query: queryPageService,
  });

  const { data: dataCategories, loading: loadingCategories } = useGql({
    query: queryCategoriesWithServices,
  });

  const currentCategoryData = dataCategories?.categories.data.find(
    (category: iCategorie) => category.attributes.slug === slug
  );

  useEffect(() => {
    // Navigate to first category if slug is wrong or undefined
    if (!loadingCategories && !currentCategoryData) {
      navigate(
        `/services/${dataCategories?.categories.data[0].attributes.slug}`,
        {
          replace: true,
        }
      );
    }
  }, [
    currentCategoryData,
    dataCategories?.categories.data,
    loadingCategories,
    navigate,
  ]);

  const categoryName =
    currentCategoryData?.attributes?.name &&
    `| ${currentCategoryData?.attributes?.name}`;

  setJsonLd({ category: currentCategoryData });

  setPageTitle(
    `${pageServiceData?.pageService.data.attributes.pageTitle} ${categoryName}`
  );
  setMeta(
    pageServiceData?.pageService.data.attributeskeywords,
    pageServiceData?.pageService.data.attributesmetaDescription
  );

  return (
    <div className="services-container page-container">
      <PageHeader
        title={pageServiceData?.pageService.data.attributes.pageTitle}
      />
      {!loadingCategories && (
        <div className="main-container">
          <div className="page-content">
            <ul className="services-nav-list">
              {dataCategories?.categories.data?.map((categorie: iCategorie) => (
                <li className="item" key={categorie.attributes.name}>
                  <NavLink
                    className="link"
                    to={`/services/${categorie.attributes.slug}`}
                    title={categorie.attributes.name}
                  >
                    <span className="title">{categorie.attributes.name}</span>
                  </NavLink>
                </li>
              ))}
            </ul>
            <div className="list-services-container">
              <ServicesList category={currentCategoryData} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Services;
