import React from "react";
import MarkdownView from "react-showdown";

import { useGql } from "../Core/gqlHooks";
import { queryProducts, queryPageProducts } from "../Core/services";
import { API_URL } from "../Core/Constant";
import PageHeader from "../Components/pageHeader/PageHeader";
import PageSubHeader from "../Components/pageSubHeader/PageSubHeader";
import { iProduct } from "../Core/interface";

import "./Produits.scss";
import { setMeta, setPageTitle, setJsonLd } from "../Core/utils";

const Produits = () => {
  const { data: dataProducts, loading } = useGql({
    query: queryProducts,
  });

  const { data: dataPageProduct } = useGql({
    query: queryPageProducts,
  });

  setJsonLd({});
  setPageTitle(dataPageProduct?.pageProduct.data.attributes.pageTitle);
  setMeta(dataPageProduct?.pageProduct.data.attributes.keywords);

  return (
    <div className="produits-container page-container">
      <PageHeader title="Les Produits" />
      <div className="main-container">
        <div className="page-content">
          <div className="spacer col-left" />
          <div className="produits col-center">
            <PageSubHeader
              title={dataPageProduct?.pageProduct.data.attributes.title}
              intro={dataPageProduct?.pageProduct.data.attributes.intro}
            />

            <div className="coup-de-coeurs-container">
              <h2>Nos coup de coeur</h2>
              <div className="coup-de-coeurs">
                {!loading &&
                  dataProducts?.products.data?.map((product: iProduct) => {
                    const imageUrl = `${API_URL}/uploads/${product.attributes.image.data.attributes.hash}${product.attributes.image.data.attributes.ext}`;
                    return (
                      <div className="coup-de-coeur" key={product.id}>
                        <div
                          className="coup-de-coeur-bg"
                          style={{
                            backgroundImage: `url(${imageUrl})`,
                          }}
                        >
                          <img
                            className="coup-de-coeur-img"
                            src={imageUrl}
                            alt={product.attributes.name}
                          />
                        </div>
                        <div className="coup-de-coeur-desc">
                          <MarkdownView
                            markdown={product.attributes.description}
                          />
                        </div>
                        <a
                          className="coup-de-coeur-link link"
                          target="_blank"
                          rel="noreferrer"
                          href={product.attributes.link}
                        >
                          {product.attributes.link}
                        </a>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="marques-container">
              <h2>Les marques</h2>
              <div className="marques">
                {!loading &&
                  dataProducts?.products.data?.map((product: iProduct) => {
                    const imageUrl = `${API_URL}/uploads/${product.attributes.image.data.attributes.hash}${product.attributes.image.data.attributes.ext}`;
                    return (
                      <div className="marque" key={product.id}>
                        <img
                          className="marque-img"
                          src={imageUrl}
                          alt={product.attributes.name}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Produits;
