import React from "react";
import "./PageHeader.scss";

const PageHeader = (props: { title: string }) => {
  const { title } = props;
  return (
    <div className="page-header-container">
      <div className="main-container">
        <header className="page-header">
          <h1 className="title">{title}</h1>
        </header>
      </div>
    </div>
  );
};

export default PageHeader;
