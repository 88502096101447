import React from "react";
import MarkdownView from "react-showdown";
import "./PageSubHeader.scss";

const PageSubHeader = (props: {
  title: string;
  intro: string | React.ReactNode;
}) => {
  const { title, intro } = props;
  return (
    <div className="page-sub-header-container">
      <header className="page-sub-header">
        <h2 className="title">{title}</h2>
        <div className="intro">
          {typeof intro === "string" ? (
            <MarkdownView markdown={intro} />
          ) : (
            intro
          )}
        </div>
      </header>
    </div>
  );
};

export default PageSubHeader;
