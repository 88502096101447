import React from "react";
import GoogleMapReact from "google-map-react";

import { useGql } from "../Core/gqlHooks";
import { queryPageContact } from "../Core/services";

import PageHeader from "../Components/pageHeader/PageHeader";

import "./Contact.scss";
import { ReactComponent as IconFacebook } from "../Assets/images/icn-facebook.svg";
import { ReactComponent as IconInstagram } from "../Assets/images/icn-instagram.svg";
import { GOOGLE_MAP_API_KEY } from "../Core/Constant";
import MarkerLogo from "../Components/map/MarkerLogo";
import { setJsonLd, setMeta, setPageTitle } from "../Core/utils";

const center = {
  lat: 45.527999065894576,
  lng: -73.59141853387052,
};

const Contact: React.FC = () => {
  const { data, loading } = useGql({
    query: queryPageContact,
  });

  setJsonLd({});
  setPageTitle(data?.pageContact.data.attributes.pageTitle);
  setMeta(data?.pageContact.data.attributes.keywords);

  return (
    <div className="contact-container page-container">
      <PageHeader title="Nous Contacter" />
      <div className="main-container">
        <div className="page-content">
          <div className="spacer col-left" />
          <div className="contact col-center">
            {!loading && (
              <div className="contact-info-container">
                <div className="contact-info">
                  <h3>Communication</h3>
                  <div className="contact-section">
                    <p className="contact-row">
                      <span className="contact-label">Tel:</span>{" "}
                      <span className="contact-value">
                        {data?.pageContact.data.attributes.tel}
                      </span>
                    </p>
                    <p className="contact-row">
                      <span className="contact-label">Courriel:</span>{" "}
                      <span className="contact-value">
                        {data?.pageContact.data.attributes.email}
                      </span>
                    </p>
                    <p className="contact-row">
                      <a
                        href={
                          data?.pageContact.data.attributes.socialMedia.facebook
                        }
                        target="_blank"
                        rel="noreferrer"
                        className="contact-value"
                      >
                        <IconFacebook className="icon-social" />
                        Facebook
                      </a>
                    </p>
                    <p className="contact-row">
                      <a
                        href={
                          data?.pageContact.data.attributes.socialMedia
                            .instagram
                        }
                        target="_blank"
                        rel="noreferrer"
                        className="contact-value"
                      >
                        <IconInstagram className="icon-social" />
                        Instagram
                      </a>
                    </p>
                  </div>
                </div>
                <div className="contact-info">
                  <h3>En personne</h3>
                  <div className="contact-section">
                    <p>
                      {data?.pageContact.data.attributes.address
                        .split("\n")
                        .map(
                          (
                            item:
                              | string
                              | number
                              | boolean
                              | React.ReactNode
                              | React.ReactElement<
                                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                  any,
                                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                  string | React.JSXElementConstructor<any>
                                >
                              | React.ReactNodeArray
                              | React.ReactPortal
                              | null
                              | undefined,
                            idx: React.Key | null | undefined
                          ) => {
                            return (
                              // eslint-disable-next-line react/no-array-index-key
                              <React.Fragment key={idx}>
                                <span>{item}</span>
                                <br />
                              </React.Fragment>
                            );
                          }
                        )}
                    </p>
                    <a
                      className="link"
                      href="https://www.google.com/maps/dir//Coifferie+EM,+5245+R.+Saint-Denis,+Montr%C3%A9al,+QC+H2J+2M1/@45.5280055,-73.5919443,19z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4cc9197d5a0abf57:0x1b4cce7cc655df6b!2m2!1d-73.5913933!2d45.5280081!3e0"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Itinéraire
                    </a>
                  </div>
                </div>
                <div className="contact-info">
                  <h3>Les heures d&apos;ouvertures</h3>
                  <div className="contact-section">
                    <ul className="heures-ouverture">
                      {data?.pageContact.data.attributes.openingHours.map(
                        (day: { day: string; hour: string }) => {
                          return (
                            <li key={day.day}>
                              <span>{day.day}</span>{" "}
                              <span className="heure">{day.hour}</span>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {!loading && (
        <div className="map-container">
          <a
            className="btn -green btn-itineraire"
            href="https://www.google.com/maps/dir//Coifferie+EM,+5245+R.+Saint-Denis,+Montr%C3%A9al,+QC+H2J+2M1/@45.5280055,-73.5919443,19z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4cc9197d5a0abf57:0x1b4cce7cc655df6b!2m2!1d-73.5913933!2d45.5280081!3e0"
            target="_blank"
            rel="noreferrer"
          >
            Itinéraire
          </a>
          <GoogleMapReact
            bootstrapURLKeys={{ key: GOOGLE_MAP_API_KEY }}
            defaultCenter={center}
            defaultZoom={17}
            options={
              {
                // mapTypeId: "2693fe25194c2331"
              }
            }
          >
            <MarkerLogo lat={center.lat} lng={center.lng} />
          </GoogleMapReact>
        </div>
      )}
    </div>
  );
};

export default Contact;
