import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import Nav from "../nav/Nav";
import { ReactComponent as LogoWithCopy } from "../../Assets/images/logo-with-copy.svg";

import "./Header.scss";

const Header = () => {
  const [menuOpened, setMenuOpened] = useState(false);

  function handleHamburgerClick() {
    menuOpened ? closeMenu() : openMenu();
  }

  function openMenu() {
    setMenuOpened(true);
    document.getElementsByTagName("body")[0].classList.add("menuOpened");
    document.getElementsByTagName("html")[0].classList.add("menuOpened");
  }

  function closeMenu() {
    document.getElementsByTagName("body")[0].classList.remove("menuOpened");
    document.getElementsByTagName("html")[0].classList.remove("menuOpened");
    setMenuOpened(false);
  }

  return (
    <header className="main-header">
      <div className="main-container">
        <div className="main-header-container">
          <NavLink className="logo-link" to="/" onClick={closeMenu}>
            <LogoWithCopy className="logo" alt="Coifferie EM" />
          </NavLink>
          <Nav
            openMenu={openMenu}
            closeMenu={closeMenu}
            handleHamburgerClick={handleHamburgerClick}
            menuOpened={menuOpened}
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
