import { iCategorie, iService, iSubCategory } from "./interface";
/* eslint-disable @typescript-eslint/no-non-null-assertion */
export function setPageTitle(title?: string) {
  document.title = title
    ? `${title} - Coifferie EM`
    : "Coifferie EM - Espace de bien-être pour tous";
}

export function setMeta(keywords: string, description?: string) {
  keywords &&
    document
      .querySelector('meta[name="keywords"]')!
      .setAttribute("content", keywords);
  description &&
    document
      .querySelector('meta[name="description"]')!
      .setAttribute("content", description);
}

export function setJsonLd({ category }: { category?: iCategorie }) {
  const offerings = {
    "@context": "http://schema.org/",
    "@type": "ItemList",
    name: "Coifferie EM",
    itemListElement: category?.attributes.subCategories?.data?.map(
      (subCategory: iSubCategory) => {
        return {
          "@type": "ItemList",
          name: subCategory.attributes.name,
          itemListElement: subCategory.attributes.service?.map(
            (service: iService) => ({
              "@type": "Product",
              name: service.name,
              offers: {
                availability: "in_stock",
                "@type": "Offer",
                priceCurrency: "CAD",
                url: `www.coifferieem.ca/services/${category.attributes.slug}`,
                price: service.price || 0,
              },
            })
          ),
        };
      }
    ),
  };

  let el = document.getElementById("ldschema") as HTMLScriptElement;

  if (!el) {
    el = document.createElement("script");
  }

  if (category) {
    el.type = "application/ld+json";
    el.id = "ldschema";
    el.innerHTML = JSON.stringify(offerings);
    document.head.appendChild(el);
  } else {
    el && el.remove();
  }
}
