import { gql } from "graphql-request";

import { graphQlClient } from "./graphql-request-client";

export const getProductsQuery = gql`
  query Products($locale: I18NLocaleCode) {
    products(locale: $locale) {
      data {
        attributes {
          name
          link
          image {
            data {
              attributes {
                name
              }
            }
          }
          coupDeCoeur
          description
        }
      }
    }
  }
`;

export const queryCategories = gql`
  query Categories($locale: I18NLocaleCode) {
    categories(sort: "order:ASC", locale: $locale) {
      data {
        attributes {
          slug
          name
          description
          order
        }
      }
    }
  }
`;

export const queryCategoriesWithServices = gql`
  query Categories($locale: I18NLocaleCode) {
    categories(sort: "order:ASC", locale: $locale) {
      data {
        id
        attributes {
          name
          description
          slug
          subCategories {
            data {
              attributes {
                name
                service {
                  name
                  id
                  price
                  description
                  plus
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const queryPageHome = gql`
  query PageHome($locale: I18NLocaleCode) {
    pageHome(locale: $locale) {
      data {
        attributes {
          keywords
          metaDescription
          pageTitle
        }
      }
    }
  }
`;

export const queryPageAbout = gql`
  query PageAbout($locale: I18NLocaleCode) {
    pageAbout(locale: $locale) {
      data {
        attributes {
          title
          intro
          pageTitle
          keywords
          carousel {
            data {
              attributes {
                name
                hash
                ext
              }
            }
          }
          keywords
        }
      }
    }
  }
`;

export const queryPageService = gql`
  query PageService($locale: I18NLocaleCode) {
    pageService(locale: $locale) {
      data {
        attributes {
          pageTitle
          keywords
          metaDescription
        }
      }
    }
  }
`;

export const queryProducts = gql`
  query Products($locale: I18NLocaleCode) {
    products(locale: $locale) {
      data {
        id
        attributes {
          name
          link
          coupDeCoeur
          description
          image {
            data {
              attributes {
                name
                hash
                ext
              }
            }
          }
        }
      }
    }
  }
`;

export const queryPageProducts = gql`
  query PageProducts($locale: I18NLocaleCode) {
    pageProduct(locale: $locale) {
      data {
        attributes {
          title
          intro
          pageTitle
          keywords
        }
      }
    }
  }
`;

export const queryPageContact = gql`
  query PageContact($locale: I18NLocaleCode) {
    pageContact(locale: $locale) {
      data {
        attributes {
          tel
          email
          address
          openingHours {
            day
            hour
          }
          socialMedia {
            facebook
            instagram
          }
          keywords
          pageTitle
        }
      }
    }
  }
`;

export async function getProducts() {
  return graphQlClient().request(getProductsQuery);
}
