import React from "react";
import { ReactComponent as LogoEMMap } from "../../Assets/images/logo-em-map.svg";

import "./MarkerLogo.scss";

interface IProps {
  lat: number;
  lng: number;
}
const MarkerLogo: React.FC<IProps> = () => {
  return (
    <div className="marker-logo">
      <LogoEMMap width={35} height={49} />
    </div>
  );
};

export default MarkerLogo;
