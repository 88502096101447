import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./Pages/Home";
import APropos from "./Pages/APropos";
import Services from "./Pages/Services";
import Produits from "./Pages/Produits";
import Contact from "./Pages/Contact";
import NotFound from "./Pages/NotFound";

import Background from "./Components/background/Background";
import Header from "./Components/header/Header";
import Footer from "./Components/footer/Footer";

function App() {
  return (
    <>
      <Background />
      <div className="App">
        <Router>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/apropos" element={<APropos />} />
            <Route path="/services/:slug" element={<Services />} />
            <Route path="/produits" element={<Produits />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </Router>
      </div>
    </>
  );
}

export default App;
