/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useEffect, useState } from "react";

import "./Background.scss";

const Background = () => {
  const canvasRef: any = useRef();
  const dpi = window.devicePixelRatio;
  const numberOfLines = 8;
  const colorPaleGreen = "#ADE1D3";

  const [windowWH, setWindowWH] = useState<
    { width: number; height: number } | undefined
  >(undefined);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    canvasRef.current = document.querySelector("#canvas")!;
    if (canvasRef.current) {
      fixDpi();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (windowWH?.height) {
      draw();
    }
    // eslint-disable-next-line
  }, [windowWH]);

  function fixDpi() {
    // get CSS height
    // the + prefix casts it to an integer
    // the slice method gets rid of "px"
    const styleHeight = +getComputedStyle(canvasRef.current)
      .getPropertyValue("height")
      .slice(0, -2);
    // get CSS width
    const styleWidth = +getComputedStyle(canvasRef.current)
      .getPropertyValue("width")
      .slice(0, -2);
    // scale the canvas
    canvasRef.current.setAttribute("height", styleHeight * dpi);
    canvasRef.current.setAttribute("width", styleWidth * dpi);
    setWindowWH({
      height: styleHeight * dpi,
      width: styleWidth * dpi,
    });
  }

  function drawLine(
    ctx: any,
    begin: any,
    end: any,
    stroke = "black",
    width = 1
  ) {
    if (stroke) {
      ctx.strokeStyle = stroke;
    }

    if (width) {
      ctx.lineWidth = width;
    }

    ctx.beginPath();
    ctx.moveTo(...begin);
    ctx.lineTo(...end);
    ctx.stroke();
  }

  function draw() {
    const ctx = canvasRef.current.getContext("2d");

    if (windowWH?.height) {
      for (let index = 0; index < numberOfLines; index += 1) {
        const beginX = Math.floor(Math.random() * windowWH.width);
        const endX = beginX / 2 + (Math.random() * windowWH.width) / 2;
        const begin = [beginX, 0];
        const end = [endX, windowWH.height];
        drawLine(ctx, begin, end, colorPaleGreen, 1);
      }
    }
  }

  return (
    <div className="background">
      <canvas id="canvas" width="100vw" height="100vh" />
    </div>
  );
};

export default Background;
