/* eslint-disable @typescript-eslint/no-explicit-any */

import { useState, useEffect } from "react";
import { graphQlClient } from "./graphql-request-client";

const defautLocale = "fr-CA";

export interface IProps {
  query: string;
  vars?: {
    [key: string]: string | number;
  };
}

interface IRequest {
  loading: boolean;
  data: Record<string, any> | null;
  error?: Record<string, any> | null;
}

export const useGql = ({ query, vars }: IProps): IRequest => {
  const varsWithLocale = {
    ...vars,
    locale: defautLocale,
  };

  const [request, setRequest] = useState<IRequest>({
    loading: true,
    data: null,
  });

  useEffect(() => {
    async function fetchData() {
      const response = await graphQlClient().request(query, varsWithLocale);
      try {
        setRequest({
          data: response,
          loading: false,
        });
      } catch (error: any) {
        setRequest({
          data: null,
          loading: false,
          error: error.errors,
        });
      }
    }
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return request;
};
