import React from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

import { useGql } from "../Core/gqlHooks";
import { queryPageAbout } from "../Core/services";

import PageHeader from "../Components/pageHeader/PageHeader";
import PageSubHeader from "../Components/pageSubHeader/PageSubHeader";

import { API_URL } from "../Core/Constant";
import { setMeta, setPageTitle, setJsonLd } from "../Core/utils";

import "./APropos.scss";

const properties = {
  duration: 3000,
  transitionDuration: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  arrows: false,
  indicators: false,
  easing: "ease",
  responsive: [
    { breakpoint: 980, settings: { slidesToShow: 5, slidesToScroll: 2 } },
    { breakpoint: 600, settings: { slidesToShow: 3, slidesToScroll: 3 } },
  ],
};

const APropos = () => {
  const { data, loading } = useGql({
    query: queryPageAbout,
  });

  setJsonLd({});
  setPageTitle(data?.pageAbout.data.attributes.pageTitle);
  setMeta(data?.pageAbout.data.attributes.keywords);

  return (
    <div className="apropos-container page-container">
      <PageHeader title="À Propos" />
      <div className="main-container">
        <div className="page-content">
          <div className="spacer col-left" />
          <div className="apropos col-center">
            {!loading && (
              <PageSubHeader
                title={data?.pageAbout.data.attributes.title}
                intro={data?.pageAbout.data.attributes.intro}
              />
            )}
          </div>
        </div>
      </div>
      {!loading && (
        <>
          <div className="carousel-wrapper">
            <Slide {...properties}>
              {data?.pageAbout.data.attributes.carousel.data.map(
                (image: { attributes: { hash: string; ext: string } }) => (
                  <div className="each-slide" key={image.attributes.hash}>
                    <div
                      style={{
                        backgroundImage: `url(${API_URL}/uploads/${image.attributes.hash}${image.attributes.ext})`,
                      }}
                    />
                  </div>
                )
              )}
            </Slide>
          </div>
        </>
      )}
    </div>
  );
};
export default APropos;
